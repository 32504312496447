<template>
    <noscript>
        <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-WL9RHQ94"
            height="0"
            width="0"
            style="display: none; visibility: hidden"
        >
        </iframe>
    </noscript>
    <CommonComponentsModalApplyTrial />
    <CommonComponentsModalUserRoleSelector />
    <main>
        <CommonComponentsHeader />
        <slot />
        <CommonComponentsFooter />
    </main>
</template>

<script setup lang="ts">
    import { onMounted } from 'vue';
    import { lazyloaderUpdate } from '../composables/useLazyload';

    onMounted(() => {
        // 如果模版中的某些含有异步资源，需额外添加一行进行刷新，确保在模版切换后至少能刷新一次
        lazyloaderUpdate();
    });
</script>

<style lang="less">
    main {
        width: 100%;
    }
</style>
